@font-face {
    font-family:'Geograph-Medium';
    src: local('Geograph-Medium'), url(./assets/fonts/Geograph-Medium.woff) format('woff');
}
@font-face {
    font-family: 'Source Sans Pro';
    src: local('SourceSansPro-Regular'), url('./assets/fonts/SourceSansPro-Regular.ttf');
}

body {
    font-family: 'Source Sans Pro', sans-serif;
}
h1, h2, h3, h4 {
    font-family: 'Geograph-Medium', sans-serif;
}

@media (min-width: 576px) {
    .position-sm-static {
        position: static !important;
    }
    .position-sm-relative {
        position: relative !important;
    }
    .position-sm-absolute {
        position: absolute !important;
    }
    .position-sm-fixed {
        position: fixed !important;
    }
    .position-sm-sticky {
        position: sticky !important;
    }
}

@media (min-width: 768px) {
    .position-md-static {
        position: static !important;
    }
    .position-md-relative {
        position: relative !important;
    }
    .position-md-absolute {
        position: absolute !important;
    }
    .position-md-fixed {
        position: fixed !important;
    }
    .position-md-sticky {
        position: sticky !important;
    }
}

@media (min-width: 992px) {
    .position-lg-static {
        position: static !important;
    }
    .position-lg-relative {
        position: relative !important;
    }
    .position-lg-absolute {
        position: absolute !important;
    }
    .position-lg-fixed {
        position: fixed !important;
    }
    .position-lg-sticky {
        position: sticky !important;
    }
}

@media (min-width: 1200px) {
    .position-xl-static {
        position: static !important;
    }
    .position-xl-relative {
        position: relative !important;
    }
    .position-xl-absolute {
        position: absolute !important;
    }
    .position-xl-fixed {
        position: fixed !important;
    }
    .position-xl-sticky {
        position: sticky !important;
    }
}